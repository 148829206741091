
import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to book an appointment
  CREATE_NEW_MAINTENANCE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/maintenances/create-new-maintenance", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to edit a maintenance
  EDIT_MAINTENANCE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/maintenances/update-maintenance`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get all their maintenances
  GET_ALL_MAINTENANCES({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/maintenances/get-my-maintenances", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific maintenance
  GET_MAINTENANCE({ }, maintenanceId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/maintenances/get-maintenance/${maintenanceId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to cancel a specific maintenance
  CANCEL_MAINTENANCE({ }, maintenanceId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/maintenances/cancel-maintenance/${maintenanceId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to pay for maintenance activities
  PAY_FOR_MAINTENANCE_ACTIVITIES({ }, data) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/maintenances/pay-for-maintenance-activities", data)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a user to get a list of pending maintenances for a specific vehicle
  GET_PENDING_MAINTENANCES_PER_VEHICLE({ }, vehicleId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/maintenances/get-pending-maintenances-for-specific-vehicle/${vehicleId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
