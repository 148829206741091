<template>
  <span>
    {{
      formatMoney({
        value: this.value,
        currency: this.currency,
        noDecimal: this.noDecimal,
      })
    }}
  </span>
</template>

<script>
import { formatMoney } from "../../utility";

export default {
  props: {
    value: "",
    currency: "",
    noDecimal: false,
  },
  // props: ["value", "currency", "isDecimal"],
  data() {
    return {
      formatMoney,
    };
  },
};
</script>

<style scoped></style>
