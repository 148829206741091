<template>
  <div class="home-footer-background-image">
    <div class="text-center">
      <div class="container justify-content-center">
        <div class="container-450 padding-20">
          <h2 class="font-size-30 blue">
            It’s easy to get started. <br />
            Start now.
          </h2>
          <form @submit.prevent="onSubmitEmail"
            class="d-flex padding-top-30 justify-content-center border-radius-6 align-items-center padding-2 margin-top-10 margin-bottom-4">
            <input class="focus-none font-size-13 border-0 padding-10 border-radius-0" required type="email"
              placeholder="Enter your email" v-model="email" />
            <div>
              <button type="submit"
                class="btn bg-blue padding-top-4 padding-10 white padding-bottom-4 padding-left-8 padding-right-8">
                Get Started
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">
          <h2 class="padding-10">Company</h2>
          <router-link class="router-link" :to="`/mechanic`">
            <div class="padding-10 cursor-pointer">Become a Mechanic</div>
          </router-link>
          <router-link class="router-link" :to="`/blog`">
            <div class="padding-10 cursor-pointer">Blog</div>
          </router-link>
          <router-link class="router-link" :to="`/about-us`">
            <div class="padding-10 cursor-pointer">About Us</div>
          </router-link>
          <router-link class="router-link" :to="`/privacy`">
            <div class="padding-10 cursor-pointer">Privacy Policy</div>
          </router-link>
          <router-link class="router-link" :to="`/terms`">
            <div class="padding-10 cursor-pointer">Terms & Conditions</div>
          </router-link>
        </div>
        <div class="col-sm-4">
          <h2 class="padding-10">Our Services</h2>
          <router-link class="router-link" :to="`/dashboard/maintenance`">
            <p class="padding-10 cursor-pointer">Maintenance</p>
          </router-link>
          <router-link class="router-link" :to="`/dashboard/spare-parts`">
            <p class="padding-10 cursor-pointer">Spare Parts</p>
          </router-link>
          <router-link class="router-link" :to="`/dashboard/fleet`">
            <p class="padding-10 cursor-pointer">Fleet</p>
          </router-link>
        </div>
        <div class="col-sm-4 row">
          <h2 class="padding-10">Contact Details</h2>
          <p class="padding-10">
            <i class="fa fa-map-marker"></i> 7 Eben-Obasuyi Street, Off Textile Mill
            <br />
            Road, Benin City, Nigeria
          </p>
          <p class="padding-10 cursor-pointer">
            <a :href="'tel:+2347019500002'"> <i class="fa fa-phone"></i> +2347019500002</a>
            <a :href="'tel:+2349162121000'"> <i class="fa fa-phone"></i> +2349162121000</a>
            <br />
            24hrs - 7 Days
          </p>
          <p class="padding-10 cursor-pointer">
            <i class="fa fa-envelope"></i> info@sendmechanic.com
          </p>
          <p class="padding-10">
            <a href="https://www.facebook.com/sendmechanic" target="_blank">
              <i class="fa fa-facebook-square facebook-color font-size-24 padding-10"></i>
            </a>
            <a href="https://twitter.com/sendmechanic" target="_blank">
              <i class="fa fa-twitter-square twitter-color font-size-24 padding-10"></i>
            </a>
            <a href="https://twitter.com" target="_blank">
              <i class="fa fa-youtube-square red font-size-24 padding-10"></i>
            </a>
          </p>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="text-center">© 2023 SendMechanic, All Rights Reserved</div>
        <p class="padding-top-10 text-center font-size-12">
          SendMechanic Ltd is an automotve services company registered in Nigeria with
          Registration Number: RC7257122
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    onSubmitEmail() {
      if (this.email) {
        const loading = this.$loading.show();
        this.$store
          .dispatch("user/CHECK_IF_EMAIL_REGISTERED", this.email)
          .then((res) => {
            loading.hide();
            if (res.success) {
              if (res.data.isRegistered) {
                this.$router.push(`/login`);
              } else {
                this.$router.push(`/signup`);
              }
            }
          })
          .catch((error) => {
            loading.hide();
            this.$notify({
              group: "general",
              text: error.message,
              type: "error",
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
