import {
    createWebHistory,
    createRouter,
} from "vue-router";
import Home from "./components/Home.vue";
import { getCookie } from "./utility";
const auth = () => {
    let token = getCookie("fUserJwt");
    return !!token;
};

const authenticatedOnly = (to, from, next) => {
    if (auth()) {
        next(); // allow to enter route
    } else {
        // Redirect to login page
        next(`/login?redirectTo=${encodeURIComponent(to.fullPath)}`);
    }
    // console.log("Line 21111111111111111111111111111111111", window.Tawk_API)

    if (to.name == "dashboard") {

        // Tawk_API.toggleVisibility()
        // Tawk_API.toggleVisibility()
    }
    // if (Tawk_API.isChatHidden()) {
    //     // Tawk_API.showWidget()
    //     Tawk_API.toggleVisibility()
    // }
};

const notForAuthenticated = (to, from, next) => {
    if (!auth()) {
        next(); // allow to enter route
    } else {
        // Redirect to dashboard main page
        next(`/dashboard`);
    }
};

const InspectionService = () =>
    import("./components/services/InspectionService.vue");
const HowItWorks = () =>
    import("./components/HowItWorks.vue");
const GetPrice = () =>
    import("./components/services/priceDisplayTemplates/GetPrice.vue");

const BlogHome = () =>
    import("./components/blog/BlogHome.vue");
const BlogArticle = () =>
    import("./components/blog/BlogArticle.vue");

const RegisterAsTechnician = () =>
    import("./components/technician/RegisterAsTechnician.vue");
const BookAPro = () =>
    import("./components/services/BookAPro.vue");

const Login = () =>
    import("./components/forms/Login.vue");
const RequestPasswordReset = () =>
    import("./components/forms/RequestPasswordReset.vue");
const Signup = () =>
    import("./components/forms/Signup.vue");
const RequestConfirmationEmail = () =>
    import("./components/forms/RequestConfirmationEmail.vue");
const EnterNewPassword = () =>
    import("./components/forms/EnterNewPassword.vue");

const TechnicianRegistration = () => import("./components/technician/technicianRegistration/Index.vue")
// const CompleteMechanicRegistration = () => import("./components/technician/CompleteMechanicRegistration.vue")

const ApplicationStatus = () => import("./components/technician/technicianRegistration/ApplicationStatus.vue")

const Dashboard = () =>
    import("./components/dashboard/Dashboard.vue");
const DashboardHome = () =>
    import("./components/dashboard/dashboardSubViews/DashboardHome.vue");
const FleetManagement = () =>
    import("./components/dashboard/dashboardSubViews/FleetManagement.vue");
const MyVehicles = () =>
    import("./components/dashboard/dashboardSubViews/fleetManagement/MyVehicles.vue");
const VehicleDetailsForm = () =>
    import("./components/dashboard/dashboardSubViews/fleetManagement/VehicleDetailsForm.vue");
const VehicleDetails = () =>
    import("./components/dashboard/dashboardSubViews/fleetManagement/VehicleDetails.vue");

const Maintenance = () =>
    import("./components/dashboard/dashboardSubViews/Maintenance.vue");
const MyMaintenances = () =>
    import("./components/dashboard/dashboardSubViews/maintenance/MyMaintenances.vue");
const MaintenanceForm = () =>
    import("./components/dashboard/dashboardSubViews/maintenance/MaintenanceForm.vue");
const MaintenanceDetails = () =>
    import("./components/dashboard/dashboardSubViews/maintenance/MaintenanceDetails.vue");

const SpareParts = () =>
    import("./components/dashboard/dashboardSubViews/SpareParts.vue");
const SparePartRequests = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/sparePartRequests/Index.vue");
const MySparePartRequests = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/sparePartRequests/MySparePartRequests.vue");
const SparePartRequestForm = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/sparePartRequests/SparePartRequestForm.vue");
const ViewAllQuotations = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/sparePartRequests/ViewAllQuotations.vue");
const CustomerViewSparePartRequest = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/sparePartRequests/ViewSparePartRequest.vue")

const MySparePartQuotations = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/myQuotations/Index.vue");
const MyInvitedRequests = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/myQuotations/MyInvitedRequests.vue");
const MySubmittedSparePartQuotations = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/myQuotations/MySparePartQuotations.vue");
const SparePartQuotationForm = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/myQuotations/SparePartQuotationForm.vue");
const SupplierViewSparePartRequest = () =>
    import("./components/dashboard/dashboardSubViews/spareParts/myQuotations/SupplierViewSparePartRequest.vue")

const Calendar = () =>
    import("./components/dashboard/dashboardSubViews/Calendar.vue");

const Wallet = () =>
    import("./components/dashboard/dashboardSubViews/Wallet.vue");
const WalletTransactions = () =>
    import("./components/dashboard/dashboardSubViews/wallet/WalletTransactions.vue");
const BankAccounts = () =>
    import("./components/dashboard/dashboardSubViews/wallet/BankAccounts.vue");

const Subscriptions = () =>
    import("./components/dashboard/dashboardSubViews/Subscriptions.vue");
const SubscriptionProducts = () =>
    import("./components/dashboard/dashboardSubViews/subscriptions/SubscriptionProducts.vue");
const MySubscriptions = () =>
    import("./components/dashboard/dashboardSubViews/subscriptions/MySubscriptions.vue");
const SubscriptionOrders = () =>
    import("./components/dashboard/dashboardSubViews/subscriptions/SubscriptionOrders.vue");
const SubscriptionCart = () =>
    import("./components/dashboard/dashboardSubViews/subscriptions/SubscriptionCart.vue");

const Settings = () =>
    import("./components/dashboard/dashboardSubViews/Settings.vue");
const ProfileSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/ProfileSettings.vue");
const TechnicianProfile = () =>
    import("./components/dashboard/dashboardSubViews/settings/TechnicianProfile.vue");
const TwoFactorAuth = () =>
    import("./components/dashboard/dashboardSubViews/settings/TwoFactorAuth.vue");
const Referral = () =>
    import("./components/dashboard/dashboardSubViews/settings/Referral.vue");
const PasswordSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/PasswordSettings.vue");
const NotificationSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/NotificationSettings.vue");
const PaymentSettings = () =>
    import("./components/dashboard/dashboardSubViews/settings/PaymentSettings.vue");

const PasswordResetLinkSent = () =>
    import("./components/staticStatusPages/PasswordResetLinkSent.vue");
const EmailConfirmation = () =>
    import("./components/staticStatusPages/EmailConfirmation.vue");
const BookingSuccessful = () =>
    import("./components/staticStatusPages/BookingSuccessful.vue");
const BookingCanceled = () =>
    import("./components/staticStatusPages/BookingCanceled.vue");

const MainViewVehicleInspectionReport = () =>
    import(
        "./components/reportViewTemplates/vehicleInspectionReport/MainViewVehicleInspectionReport.vue"
    );

const WriteReview = () =>
    import("./components/customerReview/WriteReview.vue");

const ReferAnInspector = () =>
    import("./components/referral/ReferAnInspector.vue");

const Playground = () =>
    import("./components/Playground.vue");

const NotificationsPage = () =>
    import("./components/NotificationsPage.vue");

const RegisterAsBusiness = () =>
    import("./components/business/RegisterAsBusiness.vue");

const Help = () =>
    import("./components/help/Help.vue");

const PrivacyPolicy = () =>
    import("./components/policyDocumentations/PrivacyPolicy.vue");
const TermsOfService = () =>
    import("./components/policyDocumentations/TermsOfService.vue");
const CookiePolicy = () =>
    import("./components/policyDocumentations/CookiePolicy.vue");
const AboutUs = () =>
    import("./components/AboutUs.vue")

const AdminPanel = () => import("./components/admin/AdminPanel.vue")
const ListResources = () => import("./components/admin/ListResources.vue")
const ShowResource = () => import("./components/admin/ShowResource.vue")

const PageNotFound = () =>
    import("./components/PageNotFound.vue");

const routes = [

    { path: "/", component: Home },
    { path: "/blog", component: BlogHome },
    { path: "/blog/:slug", component: BlogArticle },

    { path: "/technician", component: RegisterAsTechnician },
    // { path: "find-jobs", component: FindJobs, beforeEnter: authenticatedOnly },
    {
        path: "/book-a-pro",
        component: BookAPro,
        beforeEnter: authenticatedOnly,
    },

    { path: "/login", component: Login, beforeEnter: notForAuthenticated },
    {
        path: "/request-confirmation-email",
        component: RequestConfirmationEmail,
        beforeEnter: notForAuthenticated,
    },
    { path: "/signup", component: Signup, beforeEnter: notForAuthenticated },
    {
        path: "/resetpassword",
        component: RequestPasswordReset,
        beforeEnter: notForAuthenticated,
    },
    {
        path: "/enter-new-password",
        component: EnterNewPassword,
        beforeEnter: notForAuthenticated,
    },

    // { path: "services/property", component: HomeInspection },
    // { path: "services/:serviceType", component: VehicleInspection },
    { path: "/services/:serviceType", component: InspectionService },
    { path: "/how-it-works", component: HowItWorks },
    { path: "/services/get-price/:inspectionType", component: GetPrice },

    { path: "/technician/technician-registration/:visibleComponent", component: TechnicianRegistration, beforeEnter: authenticatedOnly },

    { path: "/technician/technician-registration/application-status", component: ApplicationStatus, beforeEnter: authenticatedOnly },
    {
        path: "/dashboard",
        component: Dashboard,
        beforeEnter: authenticatedOnly,
        children: [
            { path: "", component: DashboardHome },
            {
                path: "fleet", component: FleetManagement,
                children: [
                    { path: "", component: MyVehicles },
                    // { path: "list", component: MyVehicles },
                    { path: "new", component: VehicleDetailsForm },
                    { path: "edit/:vehicleId", component: VehicleDetailsForm },
                    { path: "show/:vehicleId", component: VehicleDetails },
                ]
            },
            {
                path: "maintenance", component: Maintenance,
                children: [
                    { path: "", component: MyMaintenances },
                    // { path: "list", component: MyMaintenances },
                    { path: "new", component: MaintenanceForm },
                    { path: "edit/:maintenanceId", component: MaintenanceForm },
                    { path: "show/:maintenanceId", component: MaintenanceDetails },
                ]
            },
            {
                path: "spare-parts", component: SpareParts,
                children: [
                    { path: "", component: MySparePartRequests },
                    {
                        path: "spare-part-requests", component: SparePartRequests,
                        children: [
                            { path: "", component: MySparePartRequests },
                            // { path: 'list', component: MySparePartRequests },
                            { path: 'new', component: SparePartRequestForm },
                            { path: 'edit/:sparePartRequestId', component: SparePartRequestForm },
                            { path: 'show/:sparePartRequestId', component: CustomerViewSparePartRequest },
                            { path: 'show-all-quotations/:sparePartRequestId', component: ViewAllQuotations },
                        ]
                    },
                    {
                        path: "my-quotations", component: MySparePartQuotations,
                        children: [
                            { path: "", component: MyInvitedRequests },
                            { path: "quotation-invitations", component: MyInvitedRequests },
                            { path: "submitted-quotations", component: MySubmittedSparePartQuotations },
                            { path: "show-request/:sparePartRequestId", component: SupplierViewSparePartRequest },
                            { path: "create-quotation/:sparePartRequestId", component: SparePartQuotationForm },
                        ]
                    },
                    // {
                    //     path: "my-spare-part-requests", component: MySparePartRequests,
                    //     children: [
                    //         { path: "new", component: SparePartRequestForm },
                    //     ]
                    // },
                    // { path: "my-quotation-invitations", component: MyInvitedRequests },
                ]
            },
            { path: "calendar", component: Calendar },
            {
                path: "wallet", component: Wallet, children: [

                    { path: "", component: WalletTransactions },
                    { path: "wallet-transactions", component: WalletTransactions },
                    { path: "bank-accounts", component: BankAccounts },
                ]
            },
            {
                path: "settings", component: Settings, children: [
                    { path: "", component: ProfileSettings },
                    { path: "user-profile", component: ProfileSettings },
                    { path: "technician-profile", component: TechnicianProfile },
                    { path: "two-factor", component: TwoFactorAuth },
                    { path: "referral", component: Referral },
                    { path: "password-settings", component: PasswordSettings },
                    { path: "notification-settings", component: NotificationSettings },
                    { path: "payment-settings", component: PaymentSettings },
                ]
            },
            {
                path: "subscriptions", component: Subscriptions,
                children: [
                    { path: "", component: SubscriptionProducts },
                    { path: "products", component: SubscriptionProducts },
                    { path: "my-subscriptions", component: MySubscriptions },
                    { path: "order-history", component: SubscriptionOrders },
                    { path: "subscription-cart", component: SubscriptionCart },
                ]
            },
        ]
    },
    { path: "/status/confirm-email", component: EmailConfirmation },
    { path: "/status/sent-password-reset", component: PasswordResetLinkSent },
    {
        path: "/status/booking-successful",
        component: BookingSuccessful,
        beforeEnter: authenticatedOnly,
    },
    {
        path: "/status/booking-canceled",
        component: BookingCanceled,
        beforeEnter: authenticatedOnly,
    },

    {
        path: "/view-report/vehicle-inspection/:orderId",
        component: MainViewVehicleInspectionReport,
        beforeEnter: authenticatedOnly,
    },

    {
        path: "/write-a-review/:orderId",
        component: WriteReview,
        beforeEnter: authenticatedOnly,
    },

    { path: "/refer-an-inspector", component: ReferAnInspector },

    { path: "/playground", component: Playground },

    { path: "/notifications", component: NotificationsPage },

    { path: "/business/register", component: RegisterAsBusiness },

    { path: "/help", component: Help },

    { path: "/privacy", component: PrivacyPolicy },
    { path: "/terms", component: TermsOfService },
    { path: "/cookie-policy", component: CookiePolicy },
    { path: "/about-us", component: AboutUs },
    {
        path: "/admin-panel",
        component: AdminPanel,
        children: [
            { path: "resources/:resource/list", component: ListResources },
            { path: "resources/:resource/:recordId/show", component: ShowResource },
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'PageNotFound',
        component: PageNotFound,
    }
];

const router = createRouter({
    // mode: 'history',
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

export default router;