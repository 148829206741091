import axiosInstance from "../helpers/axiosInstance";

const state = {
};

const mutations = {
};

const actions = {
  //For a customer to request spare part quotation
  INITIATE_OR_UPDATE_SPARE_PARTS_REQUEST({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/spare-part-requests/initiate-or-update-spare-part-request", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to add a spare part item
  ADD_SPARE_PART_ITEM({ }, formData) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/spare-part-requests/add-item`, formData)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  //For a customer to update a spare part item
  UPDATE_SPARE_PART_ITEM({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/spare-part-requests/update-item/${payload.requestId}/${payload.itemId}`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to upload a file
  UPLOAD_SPARE_PART_ITEM_FILE({ }, formData) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/spare-part-requests/upload-spare-part-item-file`, formData)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to delete a file
  DELETE_SPARE_PART_ITEM_FILE({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post("/spare-part-requests/delete-spare-part-item-file", payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get all their spare part requests
  GET_ALL_SPARE_PART_REQUESTS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/spare-part-requests/get-my-spare-part-requests", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to get a specific spare part request
  GET_SPARE_PART_REQUEST({ }, requestId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/spare-part-requests/get-spare-part-request/${requestId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to delete a spare part item
  DELETE_SPARE_PART_ITEM({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/spare-part-requests/delete-item/${payload.requestId}/${payload.itemId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to delete a spare part request
  DELETE_SPARE_PART_REQUEST({ }, requestId) {
    return new Promise((resolve, reject) => {
      axiosInstance.delete(`/spare-part-requests/delete-request/${requestId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },


  //For a customer to submit the spare part request
  SUBMIT_SPARE_PART_REQUEST({ }, requestId) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/spare-part-requests/finally-submit-spare-part-request/${requestId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to pay for a quotation
  MAKE_SPARE_PART_PAYMENT({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/spare-part-requests/accept-quotation-and-pay/${payload.requestId}/${payload.quotationId}`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a customer to update the statuses of a set delivered items
  UPDATE_SUPPLY_ITEMS_STATUS({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/spare-part-requests/update-supply-items-status`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

   //For a customer to cancel their request
   CANCEL_SPARE_PART_REQUEST({ }, requestId) {
    return new Promise((resolve, reject) => {
      axiosInstance.post(`/spare-part-requests/cancel-spare-part-request/${requestId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  // FOR SUPPLIERS
  // FOR SUPPLIERS
  // FOR SUPPLIERS
  // FOR SUPPLIERS

  //For a supplier to get their invited spare part requests
  GET_INVITED_SPARE_PART_REQUESTS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/spare-part-requests/get-my-invited-requests", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a supplier to get their submitted quotations
  GET_SUPPLIER_SPARE_PART_QUOTATIONS({ }, params) {
    return new Promise((resolve, reject) => {
      axiosInstance.get("/spare-part-requests/get-supplier-quotations", { params })
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a supplier to get a specific spare part request
  GET_AN_INVITED_SPARE_PART_REQUEST({ }, requestId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/spare-part-requests/supplier-get-an-invited-request/${requestId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

   //For a supplier to get a specific quotation
   GET_QUOTATION({ }, quotationId) {
    return new Promise((resolve, reject) => {
      axiosInstance.get(`/spare-part-requests/supplier-get-quotation/${quotationId}`)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {

            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  },

  //For a supplier to get a specific spare part request
  SUPPLIER_SUBMIT_SPARE_PART_QUOTATION({ }, payload) {
    return new Promise((resolve, reject) => {
      axiosInstance.patch(`/spare-part-requests/supplier-submit-quotation`, payload)
        .then((res) => {
          const { success } = res.data;
          if (success == true) {
            resolve(res.data)
          }
        }).catch((err) => {
          reject(err.response.data)
        })
    })
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
