<template>
  <div class="fixed-height-450">
    <div v-if="myRecentNotifications.length">
      <table class="padding-top-20 border-collapse">
        <tr v-for="(notification, index) in myRecentNotifications" :key="index"
          :class="notification.isClicked ? 'bg-light-grey' : 'bg-white'" class="border-color-light-grey border-1">
          <div class="d-flex padding-10 cursor-pointer" @click="() => {
              markNotificationAsClicked(notification._id);
              if (notification.actionPath) {
                $router.push(notification.actionPath)
                this.$emit('close')
              }
            }
            ">
            <i
              class="fa fa-bell font-size-12 logo-blue avatar-initials bg-light-grey min-width-25 max-width-25 fixed-height-25 margin-right-10"></i>
            <div>
              <div>
                {{ notification.message }}
              </div>
              <span class="grey font-size-12">
                {{ toGetRelativeTime(notification.createdAt) }}
              </span>
            </div>
          </div>
        </tr>
      </table>
      <div v-if="isMore">
        <span class="cursor-pointer" @click="() => {
            $router.push('/notifications');
          }
          ">See all notifications</span>
      </div>
    </div>
    <div v-else class="d-flex max-width-300">
      <p class="padding-top-200, padding-right-10 padding-left-10">
        There are no recent notifications
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { toGetRelativeTime } from "../../../utility";

export default {
  setup() {
    return { toGetRelativeTime };
  },
  data() {
    return {
      myRecentNotifications: [],
      limit: 5,
      isMore: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },
  methods: {
    loadRecentNotifications() {
      this.$socket.emit("GET_NOTIFICATIONS", { limit: this.limit });
    },
    markNotificationAsClicked(notificationId) {
      this.$socket.emit("MARK_NOTIFICATION_AS_CLICKED", notificationId);

      this.myRecentNotifications = this.myRecentNotifications.map((item) => {
        if (item._id == notificationId) {
          item.isClicked = true;
        }
        return item;
      });
    },
  },
  created() {
    this.loadRecentNotifications();
    this.$socket.on("NOTIFICATIONS", (data) => {
      if (data.length <= this.limit) {
        this.isMore = true;
      }
      this.myRecentNotifications = data;
    });
  },
};
</script>

<style scoped></style>