<template>
  <div>
    <div class="app__fixed-header" :class="{ 'header--fixedHeader': showFixedHeader }">
      <app-header></app-header>
    </div>
    <div>
      <notifications group="general" position="top left" width="100%"/>
    </div>
    <div class="content-container padding-top-50">
      <router-view :key="$route.path"></router-view>
    </div>
    <div v-if="isGdprCountry">
      <CookieAcceptDecline :type="'floating'" :disableDecline="true"></CookieAcceptDecline>
    </div>
    <div v-if="showFooter">
      <app-footer></app-footer>
    </div>
    <ConfirmAlert />
    <PreviewMediaFile />
  </div>
</template>

<script>
import { nextTick } from "vue";
import { mapState } from "vuex";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import CookieAcceptDecline from "./components/CookieAcceptDecline.vue";
import ConfirmAlert from "./components/utilityComponents/ConfirmAlert.vue";
import PreviewMediaFile from "./components/utilityComponents/PreviewMediaFile.vue";
import i18n from "./i18n";
import axiosInstance from "./helpers/axiosInstance";

export default {
  components: {
    appHeader: Header,
    appFooter: Footer,
    CookieAcceptDecline,
    ConfirmAlert,
    PreviewMediaFile,
  },
  data() {
    return {
      showHeaderPadding: true,
      showFixedHeader: false,
      showFooter: false,
      locale: "",
      gdprAndSimilarCountries: [
        "AT",
        "BE",
        "BG",
        "HR",
        "CY",
        "CZ",
        "DK",
        "EE",
        "FI",
        "FR",
        "DE",
        "GR",
        "HU",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MT",
        "NL",
        "PL",
        "PT",
        "RO",
        "SK",
        "SI",
        "ES",
        "SE",
        "NO", // Norway, part of the EEA with similar data protection laws
        "GB", // United Kingdom, with its own UK GDPR
        "CH", // Switzerland, similar to GDPR
        "CA", // Canada, strong data protection laws (PIPEDA)
        "AU", // Australia, Privacy Act 1988
        "NZ", // New Zealand, Privacy Act 2020
        "JP", // Japan, Act on the Protection of Personal Information (APPI)
        "KR", // South Korea, Personal Information Protection Act (PIPA)
        "BR", // Brazil, LGPD (General Data Protection Law)
        "IL", // Israel, strong data protection laws
        "SG", // Singapore, Personal Data Protection Act (PDPA)
      ],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      auth: (state) => !!state.user.userToken,
    }),
    isGdprCountry() {
      return this.gdprAndSimilarCountries.includes(this.locale.toUpperCase());
    },
  },

  watch: {
    $route(to) {
      if (to.matched[0].path == "/dashboard") {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
    },
  },
  methods: {
    onScroll() {
      // Get the current scroll position
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      // Because of momentum scrolling on mobiles, we shouldn't continue if it is less than zero

      if (currentScrollPosition < 0) {
        return;
      }
      if (currentScrollPosition < 40) {
        this.showFixedHeader = false;
      } else {
        this.showFixedHeader = true;
      }
    },

    setScreenWidth(event) {
      this.$store.commit(
        "utilities/SET_CURRENT_SCREEN_WIDTH",
        event.currentTarget.innerWidth
      );
    },

    handleVisibilityChange() {
      if (document.hidden) {
        // this.$socket.disconnect();
        // console.log("Line 1066666666666666666", "Goodbye, world!");
        this.$store.commit("user/SET_APP_ACTIVE", false);
      } else {
        // this.$socket.connect();
        // User came back to the page
        // console.log("Line 1099999999999999999", "Welcome back!");

        this.$store.commit("user/SET_APP_ACTIVE", true);

        this.initializeSockets();
      }
    },

    initializeSockets() {
      if (this.auth) {
        this.$socket.emit("GET_WALLET_BALANCES");
        this.$socket.on("WALLET_BALANCES", (data) => {
          this.$store.commit("wallet/SET_BALANCES", data);
        });

        setTimeout(() => {
          this.$socket.emit("GET_NO_OF_ALL_UNREAD_MESSAGES");
          this.$socket.on("UPDATE_NO_OF_ALL_UNREAD_MESSAGES", (unreadMessagesObj) => {
            this.$store.commit("user/SET_NO_OF_ALL_UNREAD_MESSAGES", unreadMessagesObj);
          });
        }, 1000);
      }
    },
  },

  created() {
    this.$store.dispatch("user/TRY_AUTO_LOGIN");

    if (this.auth) {
      this.$store.dispatch("subscription/GET_SUBSCRIPTION_CART");
    }
  },

  async mounted() {
    await nextTick(() => {
      this.$store.commit("utilities/SET_CURRENT_SCREEN_WIDTH", window.innerWidth);

      window.addEventListener("scroll", this.onScroll);
      window.addEventListener("resize", this.setScreenWidth);

      document.addEventListener("visibilitychange", this.handleVisibilityChange);

      this.initializeSockets();
    });
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);

    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  },

  unmounted() {
    window.removeEventListener("resize", this.setScreenWidth);
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
}
</style>

<style lang="scss" scoped>
.header {
  top: 0px;
  position: fixed;
  width: 100%;
  display: block;
  z-index: 10;
  background-color: white;
}

.admin-header {
  top: 0px;
  position: fixed;
  width: 100%;
  display: block;
  z-index: 10;
}

// .container-content {
//   bottom: 0px;
//   position: absolute;
//   width: 100%;
//   flex-direction: column;
//   justify-content: space-between;
//   display: flex;
// }

.relative {
  position: relative;
}

/* For desktop: */
@media screen and (min-width: 1100px) {
  .header {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .header--fixedHeader {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .container-content {
    top: 90px;
  }
}

/* For tablets: */
@media screen and (max-width: 1100px) {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .header--fixedHeader {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .container-content {
    top: 70px;
  }
}

/* For mobile phones: */
@media screen and (max-width: 620px) {
  .header {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .header--fixedHeader {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .container-content {
    top: 60px;
  }
}

.isadmin-content {
  top: 80px;
}

// .vue-notification {
//   padding: 0px;
//   margin: 0 5px 5px;

//   font-size: 12px;

//   color: #ffffff;
//   background: #44a4fc;
//   border-left: 5px solid #187fe7;
// }

// .vue-notification {
//   // styling
//   margin: 0 5px 5px;
//   padding: 10px;
//   font-size: 12px;
//   color: #ffffff;

//   // default (blue)
//   background: #44a4fc;
//   border-left: 5px solid #187fe7;

//   // types (green, amber, red)
//   &.success {
//     background: #68cd86;
//     border-left-color: #42a85f;
//   }

//   &.warn {
//     background: #ffb648;
//     border-left-color: #f48a06;
//   }

//   &.error {
//     background: #e54d42;
//     border-left-color: #b82e24;
//   }
// }</style>
