<template>
  <div>
    <modal
      name="preview-medial-file"
      classes="modal-container"
      content-class="modal-content max-width-900"
      v-model="isModalVisible"
      @before-open="handSetParams"
      :click-to-close="true"
    >
      <div v-if="isModalVisible">
        <div class="bg-white border-radius-8">
          <ModalHeader name="preview-medial-file" title="Preview" />
        </div>
        <div class="row justify-content-center padding-bottom-10 padding-top-10">
          <iframe
            class="stretch-full"
            height="400"
            :src="fileUrl"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="allowFullScreen"
          ></iframe>
        </div>
      </div>
    </modal>
  </div>
</template>
<script>
import ModalHeader from "./ModalHeader.vue";
export default {
  components: {
    ModalHeader,
  },

  data() {
    return {
      isModalVisible: false,
      fileUrl: "",
    };
  },
  methods: {
    handSetParams(event) {
      this.fileUrl = event.ref.params._value.fileUrl;
    },
  },
};
</script>

<style scoped></style>
