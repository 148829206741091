<template>
  <div class="bg-white margin-sm-30 padding-sm-20 padding-10 max-width-420 box-shadow">
    <div class="d-flex justify-content-center padding-bottom-20">
      <img src="../../../../assets/images/lightning-icon.png" />
    </div>
    <div class="padding-bottom-20">
      <h2 class="d-flex text-center justify-content-center yellowish-brown">
        {{ product.name }}
      </h2>
      <div class="d-flex justify-content-center padding-top-20 grey">
        {{ product.description }}
      </div>
    </div>
    <div v-if="product.monthlyDiscount > 0"
      class="d-flex justify-content-center align-items-center red padding-bottom-10">
      <FormattedMoney class="padding-right-6 text-decoration-line-through" :value="product.monthlyPrice"
        :currency="product.currency" /> <span> per
        vehicle/mo</span>
      <span class="font-size-12 padding-left-6">
        - {{ product.monthlyDiscount * 100 }}% OFF
      </span>
    </div>
    <div class="d-flex justify-content-center padding-bottom-10">
      <h1 class="font-size-22">
        <FormattedMoney :value="(1 - product.monthlyDiscount) * product.monthlyPrice" :currency="product.currency" />/vehicle/mo
      </h1>
    </div>
    <div class="d-flex justify-content-center align-items-center dark-red">
      <div>
        <b>
          <FormattedMoney
            :value="(1 - product.monthlyDiscount) * (1 - product.annualDiscount) * product.monthlyPrice * 12"
            :currency="product.currency" />
        </b><span> when billed annually. </span><span v-if="product.annualDiscount > 0">
          Save {{ (1 - (1 -
            product.monthlyDiscount) * (1 -
              product.annualDiscount)).toFixed(2)
            * 100 }}%. </span>
      </div>
    </div>
    <div class="d-flex justify-content-center margin-top-10">
      <span class="d-flex align-items-center margin-10" :class="{
        'yellowish-brown': term == 'monthly',
        'font-weight-600': term == 'monthly',
        grey: term == 'annually'
      }" @click="term = 'monthly'">
        <input class="margin-right-6 margin-left-4" v-if="term != 'monthly'" type="radio" name="term" v-model="term"
          value="monthly" />
        <img v-else class="fixed-width-20 margin-right-4" src="../../../../assets/svg/radio-button-checked.svg" />
        Billed Monthly
      </span>
      <span class="d-flex align-items-center margin-10" :class="{
        'yellowish-brown': term == 'annually',
        'font-weight-600': term == 'annually',
        grey: term == 'monthly',
      }" @click="term = 'annually'">
        <input class="margin-right-6 margin-left-4" v-if="term != 'annually'" type="radio" name="term" v-model="term"
          value="annually" />
        <img v-else class="fixed-width-20 margin-right-4" src="../../../../assets/svg/radio-button-checked.svg" />
        Billed Annually
      </span>
    </div>
    <div class="grey padding-sm-20 padding-10">
      <div v-for="(f, i) in product.features" :key="i" class="d-flex align-items-start margin-bottom-10">
        <img class="fixed-width-20 margin-right-10 margin-top-2" src="../../../../assets/images/sub-check-icon.png" />
        {{ f }}
      </div>
    </div>

    <div class="d-flex justify-content-center stretch-full margin-bottom-10">
      <button type="button" class="bg-logo-yellow logo-black border-color-logo-yellow padding-10 padding-left-20 padding-right-20 
            border-radius-10 max-width-250" @click="() => onAddToCart(product._id, term)">
        <b>Add to Cart</b></button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { formatDate, snakeToStandardCasing } from "../../../../utility";
import ModalHeader from "../../../utilityComponents/ModalHeader.vue";
import FormattedMoney from "../../../utilityComponents/FormattedMoney.vue";

export default {
  components: {
    ModalHeader,
    FormattedMoney
  },
  props: ["product", "onAddToCart"],
  data() {
    return {
      formatDate,
      snakeToStandardCasing,
      products: [],
      isGoToCartOptionsVisible: false,
      term: "annually"
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
    }),
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped></style>
