<template>
  <div>
    <div>
      <div>
        <!-- <img
          class="max-width-60 float-end justify-content-center-hidden-sm position: relative"
          src="../assets/images/hero.svg"
        /> -->
        <div class="row">
          <div class="col-sm-6">
            <div class="stretch-full-md padding-left-10">
              <div
                class="font-size-30 line-height-46 font-size-md-40 line-height-md-60 font-weight-600 yellowish-brown">
                <!-- <div class="dark-blue">
                  Eagle-eyed
                  <span class="gold-yellow">Inspection</span> services at your
                  beck and call.
                </div> -->
                Hassle-Free, Quick and Reliable Vehicle Repairs, Anytime, Anywhere.
              </div>
              <br />
              <p class="font-size-20 line-height-30 font-weight-400 grey">
                We have competent vehicle mechanics and trustworthy spare parts dealers
                all across Nigeria.
              </p>
              <div
                class="row justify-content-center max-width-380 justify-content-between padding-top-20 padding-bottom-20">
                <div class="col-sm-6 margin-top-10 margin-bottom-10">
                  <router-link :to="'/dashboard/maintenance?bookingMaintenance=true'">
                    <button
                      class="btn bg-logo-black white font-size-16 line-height-24 padding-10 max-width-180 fixed-height-55 border-radius-6"
                      type="button">
                      Book a Mechanic &nbsp;
                      <i class="fa fa-angle-right"></i></button></router-link>
                </div>
                <div class="col-sm-6 margin-top-10 margin-bottom-10">
                  <router-link :to="`/how-it-works`">
                    <button
                      class="btn bg-logo-yellow black font-size-16 line-height-24 border-color-logo-yellow padding-8 max-width-160 fixed-height-55 border-radius-6"
                      type="submit">
                      How it Works &nbsp; <i class="fa fa-angle-right"></i>
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
            <!-- <div>
              <img
                class="max-width-100 float-start justify-content-center-hidden-sm padding-right-20"
                src="../assets/images/Vector.svg"
              />
            </div> -->
          </div>
          <div class="col-sm-5">
            <img class="max-width-550" src="../assets/images/mechanic-location.jpg" />
          </div>
        </div>
        <!-- <div class="d-flex font-size-20 line-height-30 font-weight-400 justify-content-center">
          Trusted by over 10,000+ happy clients
        </div> -->
        <!-- <div class="row justify-content-center padding-18 padding-bottom-66">
          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="max-width-150"
              src="../assets/images/Google.svg"
            />
          </div>
          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="max-width-80 padding-8"
              src="../assets/images/adidas.svg"
            />
          </div>
          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="padding-8 max-width-200"
              src="../assets/images/corry.svg"
            />
          </div>

          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="padding-8 max-width-150"
              src="../assets/images/Amazon.svg"
            />
          </div>

          <div class="col-sm-2 d-flex justify-content-center">
            <img
              class="padding-8"
              src="../assets/images/Twitter.svg"
            />
          </div>
        </div> -->
      </div>
      <div class="justify-content-center padding-top-40 padding-top-md-40">
        <div class="text-center justify-content-center">
          <div class="red font-size-18 font-weight-400">Our Services</div>
        </div>
      </div>
      <div class="row padding-bottom-0">
        <div
          class="padding-top-20 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10">
          <div class="row text-center">
            <div class="col-md-4 padding-10">
              <div class="container-radius-20 bg-lighter-grey padding-10 padding-md-30">
                <div class="padding-left-10 padding-left-md-0">
                  <b class="font-size-20 logo-black line-height-30"> Maintenance </b>
                  <p class="padding-top-10 font-size-14">
                    Schedule and get your vehicle to our nearest garage so our mechanic
                    can do a thorough servicing
                  </p>
                </div>
                <br />
                <div class="padding-top-40 padding-bottom-20">
                  <img class="max-width-160 margin-bottom-6" src="../assets/images/car-service.jpg" />
                </div>

                <router-link :to="'/dashboard/maintenance?bookingMaintenance=true'">
                  <button
                    class="btn bg-logo-black white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                    type="submit">
                    <div class="d-flex">
                      <div class="padding-2">Book Mechanic &nbsp;</div>
                      <img height="" class="margin-bottom-0" src="../assets/images/arrow-right-circl.svg" />
                    </div>
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-md-4 padding-10">
              <div class="container-radius-20 bg-lighter-grey padding-10 padding-md-30">
                <div class="padding-left-10 padding-left-md-0">
                  <b class="font-size-20 logo-black line-height-30"> Spare Parts </b>
                  <p class="padding-top-10 font-size-14">
                    We have a national network of reliable spare parts suppliers that can meet your needs
                  </p>
                </div>
                <br />
                <div class="padding-top-20 padding-bottom-20">
                  <img class="max-width-150 margin-bottom-6" src="../assets/images/spare-part.jpg" />
                </div>

                <router-link :to="'/dashboard/spare-parts/spare-part-requests/new'">
                  <button
                    class="btn bg-logo-black white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                    type="submit">
                    <div class="d-flex">
                      <div class="padding-2">Buy Spare Parts &nbsp;</div>
                      <img height="" class="margin-bottom-0" src="../assets/images/arrow-right-circl.svg" />
                    </div>
                  </button>
                </router-link>
              </div>
            </div>
            <div class="col-md-4 padding-10">
              <div class="container-radius-20 bg-lighter-grey padding-10 padding-md-30">
                <div class="padding-left-10 padding-left-md-0">
                  <b class="font-size-20 logo-black line-height-30"> Fleet </b>
                  <p class="padding-top-10 font-size-14">
                    We can help you keep records that include maintenance history and purchased spare parts
                    for your vehicles
                  </p>
                </div>
                <br />
                <div class="padding-top-20 padding-bottom-28">
                  <img class="max-width-190 margin-bottom-6" src="../assets/images/car.png" />
                </div>

                <router-link :to="`/dashboard/fleet?isAddingNewVehicle=true`">
                  <button
                    class="btn bg-logo-black white font-size-16 line-height-24 padding-10 width-100 border-radius-6"
                    type="submit">
                    <div class="d-flex">
                      <div class="padding-2">Get Started &nbsp;</div>
                      <img height="" class="margin-bottom-0" src="../assets/images/arrow-right-circl.svg" />
                    </div>
                  </button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="home-subscription-background-image">
          <div class="text-center">
            <h1 class="padding-top-20 padding-bottom-20 yellowish-brown">
              Subscriptions
            </h1>
            <span class="padding-10 yellowish-brown">
              Take control of your routine maintenance schedules with our flexible and affordable subscription plans
            </span>
          </div>
          <div class="row" ref="#subscriptions">
            <div class="col-md-5 relative padding-50 padding-top-110 padding-top-sm-150 padding-top-md-200">
              <img width="80%" class="padding-8" src="../assets/images/home-sub-left-bg.png" />
              <div class="stretch-full absolute top-0 left-0 d-flex justify-content-center padding-top-20 padding-left-sm-20 
              padding-right-10 padding-right-sm-20 z-index-0">
                <img class="col-3 padding-left-10" src="../assets/svg/add-collaborators.svg" />
                <img class="col-3 padding-left-10" src="../assets/svg/get-reminders.svg" />
                <img class="col-3 padding-left-10" src="../assets/svg/upload-pictures.svg" />
                <img class="col-3 padding-left-10" src="../assets/svg/sub-calendar.svg" />
              </div>
            </div>
            <div class="col-md-7 d-flex justify-content-center">
              <div class="margin-10">
                <SubscriptionItem :product="subscriptionProduct" :onAddToCart="(productId, term) => {
                  if (auth) {
                    onAddSubscriptionItemToCart(productId, term)
                  } else {
                    $router.push('/dashboard/subscriptions')
                  }
                }" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center padding-10 bg-logo-yellow container">
      <div class="text-center">
        <span class="red font-size-18 line-height-22">Our Process</span>
        <div
          class="d-flex justify-content-center font-size-40 line-height-56 font-size-md-64 line-height-md-96 font-weight-400">
          <span> How it Works</span>
        </div>
      </div>
      <div class="row container text-center padding-bottom-40">
        <div class="col-lg-4 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10">
          <div>
            <!-- <img class="margin-bottom-6" src="../assets/images/number.png" /> -->

            <div class="d-flex justify-content-center padding-top-44 padding-bottom-44">
              <span
                class="home-circle-number fixed-width-55 fixed-height-55 font-size-28 font-weight-600 bg-rich-blue">1</span>
            </div>
            <div class="padding-left-10 padding-left-md-0">
              <div class="font-size-16 line-height-20 font-size-md-20 line-height-md-30 font-weight-400">
                You Book a Mechanic
              </div>
              <p class="font-size-14 line-height-20 font-size-md-16 line-height-md-24 logo-black padding-top-10">
                Tell us where the vehicle is and we will send a mechanic to the location
                immediately
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10">
          <div>
            <!-- <img class="margin-bottom-6" src="../assets/images/number2.png" /> -->
            <div class="d-flex justify-content-center padding-top-44 padding-bottom-44">
              <span
                class="home-circle-number fixed-width-55 fixed-height-55 font-size-28 font-weight-600 bg-red">2</span>
            </div>
            <div class="padding-left-10 padding-left-md-0">
              <div class="font-size-16 line-height-20 font-size-md-20 line-height-md-30 font-weight-400">
                We Send a Mechanic
              </div>
              <p class="font-size-14 line-height-20 font-size-md-16 line-height-md-24 logo-black padding-top-10">
                The mechanic is dispatched to the location, they check the fault, we order
                and ship the spare parts, if need be, and the vehicle gets fixed
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 padding-bottom-20 padding-left-0 padding-right-0 padding-left-md-10 padding-right-md-10">
          <!-- <img class="margin-bottom-6" src="../assets/images/number3.png" /> -->
          <div class="d-flex justify-content-center padding-top-44 padding-bottom-44">
            <span
              class="home-circle-number fixed-width-55 fixed-height-55 font-size-28 font-weight-600 bg-green">3</span>
          </div>
          <div class="padding-left-10 padding-left-md-0">
            <div class="font-size-16 line-height-20 font-size-md-20 line-height-md-30 font-weight-400">
              Your Vehicle Is Back On The Road
            </div>
            <p class="font-size-14 line-height-20 font-size-md-16 line-height-md-24 logo-black padding-top-10">
              After the repair is concluded and test runned, a report is created and an
              invoice issued for the job, if not already paid.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row max-width-1300">
      <div class="col-md-7 padding-sm-10 padding-bottom-10 padding-bottom-md-10">
        <div class="padding-10">
          <p class="font-size-16 red padding-top-10">Why choose us?</p>
          <br class="d-block-hidden-md" />
          <h1 class="font-size-30 line-height-46 font-size-md-46 line-height-md-66 font-weight-400">
            We provide the best vehicle repairs and servicing in the country
          </h1>
        </div>
      </div>
      <div class="col-md-5 container padding-bottom-0 padding-bottom-md-0">
        <div class="row padding-top-10">
          <div class="col-md-6">
            <div class="container-radius-20 bg-white margin-10 padding-20 box-shadow border-radius-8">
              <div class="padding-top-0">
                <img class="max-width-30 margin-bottom-6" src="../assets/images/shield-tick.svg" />
              </div>
              <div>
                <p class="font-size-20 font-weight-400 line-height-30">Quality Service</p>
                <p class="font-weight-400 font-size-16 line-height-24 grey padding-top-10">
                  Our vehicle servicing and repairs are the best in the country
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6 white">
            <div class="container-radius-20 bg-logo-black margin-10 padding-20 border-radius-8">
              <div class="padding-top-0">
                <img class="max-width-30 margin-bottom-6" src="../assets/images/award.svg" />
              </div>
              <div>
                <p class="font-size-20 logo-yellow font-weight-400 line-height-30">
                  Qualified Mechanics
                </p>
                <p class="font-weight-400 font-size-16 logo-yellow line-height-24 padding-top-10">
                  Our mechanics are top notch, with the appropriate education and
                  experience
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="container-radius-20 margin-10 padding-20 box-shadow border-radius-8">
              <div class="padding-top-0">
                <img class="max-width-30 margin-bottom-6" src="../assets/images/cpu-setting.svg" />
              </div>
              <div>
                <p class="font-size-20 font-weight-400 line-height-30">Minimal Cost</p>
                <p class="font-weight-400 font-size-16 line-height-24 grey padding-top-10">
                  Save cost by using reliable mechanics and original spare parts supplied
                  by our vendors
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="container-radius-20 margin-10 padding-20 box-shadow border-radius-8">
              <div class="padding-top-0">
                <img class="max-width-30 margin-bottom-6" src="../assets/images/convertshape.svg" />
              </div>
              <div>
                <p class="font-size-20 font-weight-400 line-height-30">
                  High Flexibility
                </p>
                <p class="font-weight-400 font-size-16 line-height-24 grey padding-top-10">
                  You can make schedule changes and our mechanics can easily adjust
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="padding-10 row">
      <div class="home-testimonial-row-2-image"></div>
    </div> -->
    <div class="row justify-content-center">
      <div
        class="col-md-6 bg-logo-yellow padding-10 padding-top-20 border-bottom-left-radius-10 border-top-left-radius-10">
        <div class="font-size-16 line-height-24 font-size-md-24 line-height-md-32 font-weight-400">
          Join countless customers taking charge of their vehicular assets by using
          SendMechanic
        </div>

        <div class="d-flex margin-top-md-200 padding-top-70 padding-top-md-180 align-items-end">
          <div>
            <img class="max-width-120 margin-bottom-md-10 padding-10" src="../assets/images/app-store.png" />
          </div>
          <div>
            <a target="_blank">
              <img class="max-width-130 margin-bottom-md-10 padding-10" src="../assets/images/google-play.png" />
            </a>
          </div>
          <div></div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="home-testimonial-row-1-image"></div>
      </div>
    </div>

    <SubscribeToNewsletter />
  </div>
</template>

<script>
import { mapState } from "vuex";
import FormattedMoney from "./utilityComponents/FormattedMoney.vue";
import SubscribeToNewsletter from "./utilityComponents/SubscribeToNewsletter.vue";
import SubscriptionItem from "./dashboard/dashboardSubViews/subscriptions/SubscriptionItem.vue"
import axiosInstance from "../helpers/axiosInstance";
export default {
  components: {
    SubscribeToNewsletter,
    FormattedMoney,
    SubscriptionItem
  },
  data() {
    return {
      subscriptionProduct: {}
    };
  },
  computed: {
    ...mapState({
      auth: (state) => !!state.user.userToken,
    })
  },
  methods: {
    slider() {
      setInterval(() => {
        this.currentSlideState++;
        if (this.currentSlideState > 3) {
          this.currentSlideState = 1;
        }
      }, 5000);
    },

    onAddSubscriptionItemToCart(productId, term) {
      const loading = this.$loading.show();
      this.$store
        .dispatch("subscription/ADD_SUBSCRIPTION_ITEM_TO_CART", {
          productId,
          noOfMonths: term == "annually" ? 12 : 1
        })
        .then((res) => {
          if (res.success) {
            this.$notify({
              group: "general",
              text: res.message,
              type: "success",
            });
            this.$router.push('/dashboard/subscriptions/subscription-cart')
          }
        })
        .catch((error) => {
          this.$notify({
            group: "general",
            text: error.message,
            type: "error",
          });
        })
        .finally(() => {
          loading.hide();
        })
    }
    // onScroll() {
    //   // checking for visibility
    //   if (
    //     this.$refs.row3RightHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow3 = true;
    //   }
    //   if (
    //     this.$refs.row4LeftHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow4 = true;
    //   }
    //   if (
    //     this.$refs.row5RightHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow5 = true;
    //   }
    //   if (
    //     this.$refs.row6LeftHeight.getBoundingClientRect().top <
    //     window.innerHeight
    //   ) {
    //     this.animateRow6 = true;
    //   }
    //   if (
    //     this.$refs.row7Height.getBoundingClientRect().top < window.innerHeight
    //   ) {
    //     this.animateRow7 = true;
    //   }
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.slider();
      // this.onScroll();
      // window.addEventListener("scroll", this.onScroll);

      axiosInstance.get("/users/get-subscription-products").then((res) => {
        if (res.data && res.data[0]) {
          this.subscriptionProduct = res.data[0]
        }
      });
    });

    const hash = this.$route.hash;
    setTimeout(() => {
      if (hash == "#subscriptions") {
        window.scrollTo({
          top: this.$refs[hash].offsetTop - 70,
          left: 0,
          behavior: "smooth",
        });
      }
    }, 0)
  },
  beforeUnmount() {
    // window.removeEventListener("scroll", this.onScroll);
  },
};
</script>
