import { io } from "socket.io-client";

const hostname = window.location.hostname
const protocol = window.location.protocol
const productionBaseURL = protocol + "//" + hostname
const baseURL = process.env.NODE_ENV == "production" ? productionBaseURL : process.env.VUE_APP_BACKEND_BASE_URL

const socket = io(baseURL, {
    transports: ['websocket'],
    upgrade: false,
    reconnection: true,
})

export default socket